import React from 'react'
import Particles from 'react-particles-js'
import { lifecycle } from 'recompose'

export const particlesParams = {
  fps_limit: 60,
  particles: {
    number: {
      value: 60,
      density: {
        enable: false,
        value_area: 500,
      },
    },
    line_linked: {
      enable: true,
      distance: 40,
      opacity: 0.65,
    },
    move: {
      radius: 30,
      speed: 2.5,
    },
    size: {
      value: 4,
    },
    opacity: {
      anim: {
        enable: true,
        opacity_min: 0.45,
        speed: 5,
        sync: false,
      },
      value: 0.75,
    },
  },
  polygon: {
    // "debug": true,
    enable: true,
    scale: 0.65,
    type: 'inside',
    move: {
      radius: 10,
    },
    url: '',
    // "inline": {
    //   "arrangement": "random-length"
    // },
    draw: {
      enable: true,
      stroke: {
        color: 'rgba(255, 255, 255, .5)',
      },
    },
  },
  retina_detect: false,
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'grab',
      },
    },
    modes: {
      bubble: {
        size: 6,
        distance: 20,
      },
      grab: {
        line_linked: {
          opacity: 0.85,
        },
        distance: 100,
      },
    },
  },
}

const ParticlesGuard = lifecycle({
  shouldComponentUpdate() {
    return false
  },
})(props => <Particles {...props} />)

export default ParticlesGuard
