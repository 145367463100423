import React from 'react'
import classnames from 'classnames'
import ReactPlayer from 'react-player'

function ResponsivePlayer({ className, ...props }) {
  return (
    <div className={classnames('player-wrapper', className)}>
      <ReactPlayer
        className='react-player'
        {...props}
        width='100%'
        height='100%'
      />
    </div>
  )
}

export default ResponsivePlayer
