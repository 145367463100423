/**
 * Just a SSR-capable wrapper around react-odometerjs component
 */

import React, { Component } from 'react'
import 'odometer/themes/odometer-theme-default.css'

export default class Odometer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      Comp: null,
    }
  }

  componentDidMount() {
    this.setState({ Comp: require('react-odometerjs').default })
  }

  render() {
    const { Comp } = this.state

    if (!Comp) {
      return this.props.loading || <span>loading&hellip;</span>
    }

    return <Comp {...this.props} />
  }
}
