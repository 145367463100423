import React, { useCallback, useEffect, useState, useContext } from 'react'
import { graphql } from 'gatsby'
import posed from 'react-pose'
import VisibilitySensor from 'react-visibility-sensor'
import Button from 'components/Button'
import classnames from 'classnames'
import { NominatePopupContext } from '../PopupNominate'
import { ApplyPopupContext } from '../PopupApply'

const SectionPosed = posed.div({
  enter: {
    opacity: 1,
    staggerChildren: 150,
    beforeChildren: true,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    duration: 100,
  },
})

const colEnterPose = {
  // opacity: 1,
  // x: 0,
  beforeChildren: true,
  staggerChildren: 300,
}

const NominateColLeft = posed.div({
  enter: {
    ...colEnterPose,
    delayChildren: 300,
  },

  exit: {
    // opacity: 0,
    // x: '-50%',
  },
})

const NominateColRight = posed.div({
  enter: {
    ...colEnterPose,
    delayChildren: 1200,
  },

  exit: {
    // opacity: 0,
    // x: '50%',
  },
})

function NominateSectionBase({
  nominateLeftColumn,
  nominateRightColumn,
  nominateActions,
  className,
  ...props
}) {
  const [mounted, setMounted] = useState(false)
  const [sectionPose, setSectionPose] = useState('')

  useEffect(() => {
    setMounted(true)
  }, [])

  const handleVisibilityChange = useCallback(
    payload => {
      if (mounted) {
        setSectionPose(
          sectionPose === 'enter'
            ? sectionPose
            : payload === true
            ? `enter`
            : `exit`
        )
      }
    },
    [sectionPose, mounted]
  )

  const { togglePopup: nominatePopupToggle } = useContext(NominatePopupContext)
  const { togglePopup: applyPopupToggle } = useContext(ApplyPopupContext)

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility={true}
      minTopValue={300}
    >
      <SectionPosed
        initialPose="exit"
        pose={sectionPose}
        className={classnames(
          `bg-grey-lightest py-10 xl:pt-6 xl:pb-10 xxl:pt-10 xxl:pb-16`,
          className
        )}
        {...props}
      >
        <div className="container overflow-hidden px-0">
          <div className="flex flex-wrap mx-2 md:mx-6 lg:mx-10">
            <NominateColLeft className="nominate-col w-full overflow-hidden px-2 md:my-10 md:px-6 lg:px-10 md:w-1/2">
              {nominateLeftColumn}
            </NominateColLeft>

            <NominateColRight className="nominate-col w-full overflow-hidden px-2 md:my-10 md:px-6 lg:px-10 md:w-1/2">
              {nominateRightColumn}
            </NominateColRight>
          </div>
        </div>

        <div className="btn-group text-center">
          {nominateActions.map(({ buttonText, buttonLink }, idx) => {
            const btnProps = {}
            let onClick = null

            switch (buttonLink) {
              case '#nominate':
                btnProps.onClick = nominatePopupToggle
                break
              case '#apply':
                btnProps.onClick = applyPopupToggle
                break
              default:
                btnProps.link = buttonLink
            }

            return (
              <Button
                key={`${idx}-${buttonLink}`}
                size="lg"
                variant="turquoise"
                className="text-base leading-normal uppercase"
                // icon="crown"
                link={buttonLink}
                onClick={onClick}
                {...btnProps}
              >
                {buttonText}
              </Button>
            )
          })}
        </div>
      </SectionPosed>
    </VisibilitySensor>
  )
}

const NominateSection = NominateSectionBase
// enhanceWithPopupContext()

export const homeSectionNominateFragment = graphql`
  fragment HomeSectionNominateFragment on HomeYaml {
    nominate {
      columnsMd {
        childMarkdownRemark {
          htmlAst
        }
      }

      actions {
        buttonText
        buttonLink
      }
    }
  }
`

export default NominateSection
