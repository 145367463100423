/* global google */

import { graphql, StaticQuery } from 'gatsby'
import React, { Component } from 'react'
import styled from 'styled-components'
import posed, { PoseGroup } from 'react-pose'
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap,
} from 'react-google-maps'
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer'
import MarkerClustererPlus from 'marker-clusterer-plus'

import InfoBox from 'react-google-maps/lib/components/addons/InfoBox'
import mapStyles from 'styles/google-map'
import colors from 'styles/tailwind/colors'
import screens from 'styles/tailwind/screens'
import { injectIntl, FormattedMessage } from 'react-intl'
import { withStateHandlers, compose } from 'recompose'
import Tooltip from 'components/Tooltip'
import Button from 'components/Button'
import { throttle, countBy, isEqual } from 'lodash'
import Select from 'react-select'
import VisibilitySensor from 'react-visibility-sensor'
import renderAst from 'components/AstRenderer'
import { enhanceWithBreakpoints } from 'components/enhancers'
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdClose,
} from 'react-icons/md'
import municipalitiesL10n from 'l10n/municipalities-bg.json'

MarkerClustererPlus.IMAGE_SIZES = [50, 60, 70, 80, 90]
MarkerClustererPlus.IMAGE_PATH = '/mapclusterer/m'
const PIN_SIZE = 25

const StyledCheckLabel = styled.label`
  ${({ accent }) => `
    &::before {
      border-color: ${accent};
    }
  `}
`

const StyledCheck = styled.input`
  ${({ accent }) => `
  &:checked + label::before {
    border-width: calc(25px / 2);
  }
  `}
`

const selectStyles = {
  control: provided => ({
    ...provided,
    borderRadius: 0,
  }),
}

const MapWrapperDiv = styled.div`
  min-height: 60vh;
  max-height: 125vw;
`

const ClearFiltersTriggerPosed = styled(
  posed.a({
    enter: {
      opacity: 1,
      transition: {
        ease: 'easeInOut',
      },
    },

    exit: {
      applyAtStart: {
        width: ({ element }) => element.offsetWidth,
        transition: {
          ease: 'easeInOut',
        },
      },
      applyAtEnd: { width: '' },
      opacity: 0,
    },
  })
)`
  position: absolute !important;
  top: 50%;
  right: 0;
  transform: translateY(-50%) !important;
  opacity: 0;
`

const MapFilters = React.forwardRef(
  (
    {
      activeFilters,
      handleCategoryChange,
      handleLocationSelect,
      clearFilters,
      municipalities,
      categories,
    },
    ref
  ) => (
    <div ref={ref} className="md:p-4 xxl:p-8 bg-grey-lightest">
      <div className="map-filter-group category-check-list">
        <h3 className="hidden md:block relative uppercase text-grey-darker">
          <FormattedMessage id="filtersCategoriesTitle" />

          <PoseGroup>
            {(activeFilters.categories.length > 0 ||
              activeFilters.location) && (
              <ClearFiltersTriggerPosed
                key="clear-filters"
                className="btn align-middle btn-grey-darker text-xs border-1 pl-6 pr-4"
                href="#"
                onClick={e => {
                  e.preventDefault()
                  clearFilters()
                }}
              >
                <MdClose
                  className="absolute pin-l ml-1"
                  size="1.25em"
                  style={{ transform: 'translateY(-50%)', top: '50%' }}
                />
                <FormattedMessage id="filtersClear" />
              </ClearFiltersTriggerPosed>
            )}
          </PoseGroup>
        </h3>

        <div className="flex flex-col itms-stretch">
          {categories &&
            categories.map(cat => {
              const { id, name, description, color, count } = cat
              const labelHtml = `${name}&nbsp;(${count})`
              const checked = activeFilters.categories.includes(id)

              return (
                <div
                  key={id}
                  className={`
                category-check-list-item 
                flex-no-shrink flex-grow md:mb-2 flex flex-row items-center 
                py-2 px-3 md:p-0 
                border-b border-grey-lighter md:border-0 
                text-sm xxl:text-lg
              `}
                >
                  <StyledCheck
                    id={`cat-${id}`}
                    accent={color}
                    className="filter-check"
                    type="checkbox"
                    name="category-filter"
                    value={id}
                    onChange={handleCategoryChange}
                    checked={checked}
                  />
                  {/* <input id={`cat-${id}`} className="filter-check" type="checkbox" name="category-filter" value={id} /> */}
                  <StyledCheckLabel
                    htmlFor={`cat-${id}`}
                    accent={color}
                    className="uppercase text-grey-darker leading-loose xxl:leading-tight"
                    dangerouslySetInnerHTML={{ __html: labelHtml }}
                  />
                  {description && (
                    <Tooltip
                      className="flex-no-shrink flex-no-grow text-xs pin-r align-middle ml-4 w-4 h-4 text-center rounded-full bg-grey-lighter text-white"
                      placement="right"
                      trigger="hover"
                      tooltip={description}
                    >
                      ?
                    </Tooltip>
                  )}
                </div>
              )
            })}
        </div>
      </div>

      <div className="map-filter-group p-5 md:p-0 md:mt-6 border-b">
        <h3 className="hidden md:block uppercase text-grey-darker">
          <FormattedMessage id="filtersLocationTitle" />
        </h3>

        {municipalities.length && (
          <FormattedMessage id="locationSelectPlaceholder">
            {placeholder => (
              <Select
                value={
                  (activeFilters.location &&
                    municipalities.find(
                      m => m.value === activeFilters.location
                    )) ||
                  null
                }
                isClearable={true}
                styles={selectStyles}
                placeholder={placeholder}
                onChange={handleLocationSelect}
                options={municipalities}
              />
            )}
          </FormattedMessage>
        )}
      </div>

      <div className="sm:hidden map-filter-group p-5 md:p-0 md:mt-6">
        <button
          className="btn btn-grey-darker text-sm block w-full px-6"
          disabled={
            !(activeFilters.categories.length > 0 || activeFilters.location)
          }
          href="#"
          onClick={e => {
            e.preventDefault()
            clearFilters()
          }}
        >
          <MdClose
            className="absolute pin-l ml-1"
            size="1.25em"
            style={{ transform: 'translateY(-50%)', top: '50%' }}
          />
          <FormattedMessage id="filtersClear" />
        </button>
      </div>
    </div>
  )
)

// const MapFiltersPosed = posed(MapFilters)({
//   visible: {
//     height: 'auto',
//     flip: true,
//     transition: {
//       ease: 'easeInOut',
//     },
//   },

//   hidden: {
//     applyAtEnd: { display: 'none' },
//     height: 0,
//     flip: true,
//   },
// })

const MapFilterPanelHeader = styled.header`
  overflow: hidden;

  p:last-child,
  li:last-child,
  h1:last-child,
  h2:last-child,
  h3:last-child,
  h5:last-child,
  h6:last-child {
    margin-bottom: 0;
  }
`

const FlexHeightContainer = posed.div({
  change: {
    afterChildren: true,
    applyAtEnd: {
      height: ({ element: { offsetHeight: height } }) => height,
    },
    height: 'auto',
  },
  transition: {},
})

const enhanceМоbileMapFilterPanelWithExpandStateHandlers = withStateHandlers(
  {
    mobileFiltersExpanded: false,
  },
  {
    toggleFilters: ({ mobileFiltersExpanded }) => () => ({
      mobileFiltersExpanded: !mobileFiltersExpanded,
    }),
  }
)

const enhanceMapFilterPanel = compose(
  enhanceМоbileMapFilterPanelWithExpandStateHandlers,
  enhanceWithBreakpoints
)

const MapFilterPanelEnhanced = enhanceMapFilterPanel(
  ({
    className,
    activeFilters,
    handleCategoryChange,
    handleLocationSelect,
    clearFilters,
    municipalities,
    categories,
    copy,
    isMinMd,
    mobileFiltersExpanded,
    toggleFilters,
    toggleMinimize,
    filtersMinimized,
    forwardedRef,
  }) => {
    const headerPose = isMinMd
      ? 'visible'
      : mobileFiltersExpanded
      ? 'hidden'
      : 'visible'

    const filtersPose = isMinMd
      ? 'visible'
      : mobileFiltersExpanded
      ? 'visible'
      : 'hidden'

    const filterClassBg = mobileFiltersExpanded
      ? 'bg-primary'
      : 'bg-primary-dark'

    return (
      <div
        ref={forwardedRef}
        className={`map-controls-wrapper md:w-80 xxl:w-96 max-w-full md:absolute pin-b z-10 md:mb-10 bg-white md:ml-4 xl:ml-8 ${className}`}
      >
        <FlexHeightContainer
          pose={['change', headerPose]}
          poseKey={mobileFiltersExpanded ? 'expanded' : 'collapsed'}
          withParent={false}
        >
          {headerPose === 'visible' && (
            <MapFilterPanelHeader
              className="p-4 xxl:p-8 bg-primary text-white md:text-black text-center md:text-left"
              dangerouslySetInnerHTML={{ __html: copy }}
            />
          )}

          <FormattedMessage id="filtersTitle">
            {contents => (
              <button
                className={`block md:hidden w-full ${filterClassBg} py-3 px-4 text-white text-sm font-kelson font-bold`}
                onClick={toggleFilters}
              >
                {contents}
                {(filtersPose === 'visible' && (
                  <MdClose size="1.5em" className="inline-block align-middle" />
                )) || (
                  <MdKeyboardArrowDown
                    size="1.5em"
                    className="inline-block align-middle"
                  />
                )}
              </button>
            )}
          </FormattedMessage>

          {filtersPose === 'visible' && (
            <MapFilters
              {...{
                activeFilters,
                handleCategoryChange,
                handleLocationSelect,
                clearFilters,
                municipalities,
                categories,
              }}
            />
          )}
        </FlexHeightContainer>

        <button
          className="hidden md:block absolute pin-t text-white hover:text-white bg-primary hover:bg-primary-dark text-center"
          style={{ left: '100%', width: '2rem', height: '2rem' }}
          onClick={toggleMinimize}
        >
          {!filtersMinimized && (
            <MdKeyboardArrowLeft
              size="1.5em"
              className="inline-block align-middle"
            />
          )}
          {filtersMinimized && (
            <MdKeyboardArrowRight
              size="1.5em"
              className="inline-block align-middle"
            />
          )}
        </button>
      </div>
    )
  }
)

const MapFilterPanel = React.forwardRef((props, ref) => (
  <MapFilterPanelEnhanced {...props} forwardedRef={ref} />
))

const MapFilterPanelPosed = posed(MapFilterPanel)({
  visible: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      delay: 200,
      duration: 300,
    },
  },

  hidden: {
    y: '-20%',
    opacity: 0,
    transition: {
      duration: 300,
    },
  },

  closed: {
    x: ({ element }) => {
      let styles = element.currentStyle || window.getComputedStyle(element)
      return `calc(-100% - ${styles.marginLeft})`
    },

    transition: {
      ease: 'easeInOut',
    },
  },
})

const MapNomineeInfo = styled.div`
  @media (max-width: ${screens['sm-max'].max}) {
    max-height: 50vh;
    overflow: auto;
  }
`

const DisclaimerDiv = styled.div`
  @media (max-width: 414px) {
    &.disclaimer {
      font-size: 11px !important;
    }
  }
`

const MapNomineeCTADisclaim = React.forwardRef(
  ({ className, ...props }, ref) => (
    <FormattedMessage id="nomineeCTADisclaimer">
      {content => (
        <DisclaimerDiv
          ref={ref}
          {...props}
          className={`disclaimer ${className}`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </FormattedMessage>
  )
)

class Map extends Component {
  map = null

  markerRefs = []

  state = {
    infoBoxId: null,

    onMapMounted: ref => {
      this.map = ref
    },
  }

  getFitBoundsPadding = () => {
    const {
      mapControlsElem: { current: mapControlsElem },
    } = this.props

    let left = 20

    if (this.props.isMinMd && mapControlsElem) {
      const controlsRect = mapControlsElem.getBoundingClientRect()
      left = controlsRect.left + controlsRect.width
    }

    return {
      left,
      right: 30,
      top: 20,
      bottom: 20,
    }
  }

  handleWindowResize = throttle(
    () => {
      this.fitMarkerBounds()
    },
    300,
    { leading: false, trailing: true }
  )

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize)

    if (process.env.NODE_ENV === 'development') {
      setTimeout(this.fitMarkerBounds, 150)
    } else {
      this.fitMarkerBounds()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.infoBoxId === this.state.infoBoxId) {
      if (
        isEqual(
          nextProps.pins.map(p => p.id),
          this.props.pins.map(p => p.id)
        ) &&
        isEqual(this.props.filters, nextProps.filters) &&
        this.props.isMinMd === nextProps.isMinMd &&
        this.props.lang === nextProps.lang
      ) {
        return false
      }
    }

    return true
  }

  componentDidUpdate(prevProps, prevState) {
    this.markerRefs = []

    let updateBounds

    if (this.props.pins !== prevProps.pins) {
      updateBounds = true
    }

    if (updateBounds) {
      this.fitMarkerBounds()
    }
  }

  static getDerivedStateFromProps(newProps, { infoBoxId }) {
    const { pins } = newProps
    const pinIds = pins.map(p => p.id)

    if (!pinIds.includes(infoBoxId)) {
      return {
        infoBoxId: null,
      }
    }

    return null
  }

  render() {
    const { pins, lang } = this.props

    const markersRender = []
    const { infoBoxId } = this.state
    const infoBoxOffset = new google.maps.Size(PIN_SIZE / 2, PIN_SIZE / 2)
    const infoBoxClearance = new google.maps.Size(80, 40)

    pins.forEach((item, idx) => {
      const {
        id,
        name,
        organization,
        nominatedBy,
        category,
        description,
        link,
        lat,
        lng,
      } = item

      const key = `${id}-${lang}`
      const latLng = new google.maps.LatLng({ lat, lng })
      const { color = colors.primary } = category || {}
      const infoBoxOpen = id === infoBoxId

      markersRender.push(
        <Marker
          key={key}
          ref={this.markerMounted}
          position={latLng}
          icon={this.getIconConfig({ fill: color })}
          title={name}
          onClick={() => {
            this.toggleMarkerInfoBox(id)
          }}
        >
          {(infoBoxOpen === true && (
            <InfoBox
              options={{
                position: latLng,
                pixelOffset: infoBoxOffset,
                disableAutoPan: false,
                boxClass: 'infoBox md:mr-4 md:mb-4',
                infoBoxClearance: infoBoxClearance,
                closeBoxURL: ``,
                enableEventPropagation: true,
              }}
            >
              <div className="infobox-inner relative bg-grey-lightest w-64 lg:w-80 text-sm sm:text-base font-kelson">
                <MapNomineeInfo className="map-nominee-info p-4 pb-2 md:p-6 md:pb-3 xxl:p-8 xxl:pb-4">
                  {name && (
                    <dl className="map-nominee-dl">
                      <dt>
                        <FormattedMessage id="nomineeName" />
                      </dt>
                      <dd className="text-grey-darker">{name}</dd>
                    </dl>
                  )}
                  {organization && (
                    <dl className="map-nominee-dl">
                      <dt>
                        <FormattedMessage id="nomineeOrg" />
                      </dt>
                      <dd className="text-grey-darker">{organization}</dd>
                    </dl>
                  )}
                  {description && (
                    <dl className="map-nominee-dl">
                      <dt>
                        <FormattedMessage id="nomineeDescr" />
                      </dt>
                      <dd className="text-grey-darker">{description}</dd>
                    </dl>
                  )}
                  {nominatedBy && (
                    <dl className="map-nominee-dl">
                      <dt>
                        <FormattedMessage id="nominatedBy" />
                      </dt>
                      <dd className="text-grey-darker">{nominatedBy}</dd>
                    </dl>
                  )}

                  <div className="text-center mt-4">
                    <FormattedMessage id="nomineeGetInTouch">
                      {(...content) => (
                        <Button variant="grey-darker" link={link}>
                          {content}
                        </Button>
                      )}
                    </FormattedMessage>
                  </div>

                  <button
                    className="info-box-toggle absolute pin-t pin-r hover:text-primary"
                    onClick={() => {
                      this.toggleMarkerInfoBox(id)
                    }}
                  >
                    <MdClose className="w-8 h-8 mr-2 mt-2" />
                  </button>
                </MapNomineeInfo>
                <MapNomineeCTADisclaim className="map-nominee-info text-xs text-grey-darker bg-grey-lighter px-4 py-2 md:px-6 md:py-3 xxl:px-8 xxl:py-4" />
              </div>
            </InfoBox>
          )) ||
            null}
        </Marker>
      )
    })

    return (
      <GoogleMap ref={this.state.onMapMounted} {...this.props}>
        {pins.length && (
          <MarkerClusterer
            averageCenter={true}
            zoomOnClick={false}
            gridSize={30}
            maxZoom={15}
            onClick={this.handleClusterClick}
          >
            {markersRender}
          </MarkerClusterer>
        )}
      </GoogleMap>
    )
  }

  fitMarkerBounds = (bounds = null) => {
    const map = this.map

    if (!map) {
      return
    }

    const {
      filters: { categories, location },
    } = this.props

    if (!bounds) {
      if (categories.length > 0 || location !== ``) {
        bounds = this.getAllMarkersBounds()
      } else {
        bounds = this.getDefaultMapBounds()
      }
    }

    map.fitBounds(bounds, this.getFitBoundsPadding())
  }

  markerMounted = ref => {
    if (!ref) {
      return
    }

    this.markerRefs.push(ref)
  }

  fitBounds = (bounds, opts) => {
    var map = this.map

    if (opts) {
      var scale = Math.pow(2, map.getZoom())

      /* Helper methods */

      var _convertLatLngToPixel = function(latlng) {
        var proj = map.getProjection()
        var point = proj.fromLatLngToPoint(latlng)
        return {
          x: point.x * scale,
          y: point.y * scale,
        }
      }

      var _convertPixelToLatLng = function(pixel) {
        var proj = map.getProjection()
        var point = new google.maps.Point(pixel.x / scale, pixel.y / scale)
        return proj.fromPointToLatLng(point)
      }

      var _getPixelBounds = function(bounds, cb) {
        if (map.getProjection()) {
          var returnVal = {
            sw: _convertLatLngToPixel(bounds.getSouthWest()),
            ne: _convertLatLngToPixel(bounds.getNorthEast()),
          }
          cb(returnVal)
        } else {
          google.maps.event.addListener(map, 'projection_changed', function() {
            _getPixelBounds(bounds, cb)
          })
        }
      }

      var _extendBoundsByPaddingValue = function(bounds, opts) {
        _getPixelBounds(bounds, function(pxbounds) {
          for (var prop in opts) {
            // eslint-disable-next-line default-case
            switch (prop) {
              case 'left':
                pxbounds.sw.x -= opts.left
                break
              case 'top':
                pxbounds.ne.y -= opts.top
                break
              case 'right':
                pxbounds.ne.x += opts.right
                break
              case 'bottom':
                pxbounds.sw.y += opts.bottom
                break
            }
          }
          var bounds = new google.maps.LatLngBounds(
            _convertPixelToLatLng(pxbounds.sw),
            _convertPixelToLatLng(pxbounds.ne)
          )
          map.fitBounds(bounds)
        })
      }

      _extendBoundsByPaddingValue(bounds, opts)
    } else {
      map.fitBounds()
    }
  }

  getAllMarkersBounds = () => {
    const { pins } = this.props

    const bounds = new google.maps.LatLngBounds()

    pins.forEach(({ lat, lng }) => {
      bounds.extend({ lat, lng })
    })

    return bounds
  }

  getDefaultMapBounds = () => {
    const bounds = new google.maps.LatLngBounds()

    bounds.extend({ lat: 44.215373, lng: 22.675225 })
    bounds.extend({ lat: 41.235671, lng: 25.284776 })
    bounds.extend({ lat: 43.54177, lng: 28.609119 })
    bounds.extend({ lat: 42.311833, lng: 22.360505 })

    return bounds
  }

  getIconConfig = ({ fill }) => ({
    path: `M 0,${PIN_SIZE} ${PIN_SIZE},${PIN_SIZE} ${PIN_SIZE},0 0,0 0,${PIN_SIZE} Z`,
    fillColor: fill,
    fillOpacity: 1,
    anchor: new google.maps.Point(PIN_SIZE / 2, PIN_SIZE / 2),
    strokeWeight: 0,
  })

  handleClusterClick = cluster_ => {
    const map = this.map

    // Zoom into the cluster.
    const mz = map.props.defaultOptions.maxZoom || null

    const theBounds = cluster_.getBounds()

    this.fitMarkerBounds(theBounds)

    setTimeout(() => {
      this.fitMarkerBounds(theBounds)
      // Don't zoom beyond the max zoom level
      if (mz !== null && map.getZoom() > mz) {
        map.setZoom(mz + 1)
      }
    }, 100)

    this.toggleMarkerInfoBox()
  }

  toggleMarkerInfoBox = (id = null) => {
    this.setState(({ infoBoxId }) => ({
      infoBoxId: id === infoBoxId ? null : id,
    }))
  }
}

const enhanceMap = compose(
  withGoogleMap,
  enhanceWithBreakpoints
  // onlyUpdateForKeys(['pins', 'filters', 'lang', 'isMinMd']),
)

const MapWithGoogleMap = enhanceMap(Map)
const MapGoogleMapWithScript = withScriptjs(MapWithGoogleMap)
const mapControlsElem = React.createRef()
const enhanceMapWithStateHandlers = withStateHandlers(
  {
    activeFilters: {
      categories: [],
      location: '',
    },
    mapWentVisible: false,
    inViewport: false,
    filtersMinimized: false,
  },
  {
    handleCategoryChange: ({
      activeFilters: { categories: prevCategories, ...filters },
    }) => event => {
      const { value } = event.target

      let categories = [...prevCategories]
      if (event.target.checked) {
        categories.push(value)
      } else {
        categories = categories.filter(id => id !== value)
      }

      return { activeFilters: { ...filters, categories } }
    },

    handleLocationSelect: ({ activeFilters }) => (selected, { action }) => {
      let location

      if (action === 'clear') {
        location = ''
      } else {
        location = selected.value
      }

      return { activeFilters: { ...activeFilters, location } }
    },

    clearFilters: () => () => ({
      activeFilters: { categories: [], location: `` },
    }),

    toggleMinimize: ({ filtersMinimized }) => () => ({
      filtersMinimized: !filtersMinimized,
    }),

    handleVisibilyChange: ({ mapWentVisible }) => isVisible => {
      return {
        inViewport: isVisible,
        mapWentVisible: mapWentVisible || isVisible,
      }
    },
  }
)

const recomposeMapComponent = compose(
  enhanceWithBreakpoints,
  enhanceMapWithStateHandlers,
  injectIntl
)

const NomineesMap = recomposeMapComponent(props => {
  const {
    items,
    activeFilters,
    inViewport,
    filtersMinimized,
    toggleMinimize,
    handleVisibilyChange,
    mapWentVisible,
    isMinMd,
    intl: { locale },
  } = props

  const apiKey = process.env.GATSBY_GMAPS_API_KEY
  const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&language=${locale}`

  const pins = [],
    categories = [],
    municipalities = []

  const _uniqCatIds = [] // @todo this is stupid, fix it
  const _uniqMlities = []

  const perCat = countBy(items, ({ category }) => category && category.id)

  items.forEach(({ organization, nominatedBy, ...item }) => {
    const { category, municipality } = item
    if (!item.descriptionMd[locale]) {
      return
    }

    const { childMarkdownRemark: MdNode = {} } = item.descriptionMd[locale]
    const description = MdNode.htmlAst ? renderAst(MdNode.htmlAst) : ''

    if (category && !_uniqCatIds.includes(category.id)) {
      categories.push({
        ...category,
        name: category.name[locale] || '',
        description:
          (category.description && category.description[locale]) || '',
        count: perCat[category.id] || 0,
      })
      _uniqCatIds.push(category.id)
    }

    if (municipality && !_uniqMlities.includes(municipality)) {
      let label = municipality,
        value = municipality

      if (locale !== 'en') {
        if (locale === 'bg' && municipalitiesL10n[municipality]) {
          label = municipalitiesL10n[municipality]
        }
      }

      municipalities.push({ label, value })
      _uniqMlities.push(value)
    }

    const pinObject = {
      ...item,
      description,
      name: item.name[locale],
    }

    if (nominatedBy && nominatedBy[locale]) {
      pinObject.nominatedBy = nominatedBy[locale]
    } else {
    }

    if (organization && organization[locale]) {
      pinObject.organization = organization[locale]
    }

    const {
      categories: categoryFilters,
      location: locationFilter,
    } = activeFilters

    if (
      categoryFilters.length &&
      (!pinObject.category || !categoryFilters.includes(pinObject.category.id))
    ) {
      return
    }

    if (locationFilter && locationFilter !== pinObject.municipality) {
      return
    }

    pins.push(pinObject)
  })

  const MapComponentVariant =
    typeof google !== 'undefined' && google.maps
      ? MapWithGoogleMap
      : MapGoogleMapWithScript

  const controlsPose = isMinMd ? (inViewport ? 'visible' : 'hidden') : 'visible'

  return (
    <div className="map-wrapper flex flex-col-reverse h-full md:block">
      <VisibilitySensor
        onChange={handleVisibilyChange}
        partialVisibility={true}
        minTopValue={60}
      >
        <MapWrapperDiv className="flex md:block md:h-full md:min-h-0">
          {mapWentVisible && (
            <MapComponentVariant
              googleMapURL={googleMapURL}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div className="self-stretch flex-grow relative md:h-full" />
              }
              mapElement={
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                  }}
                />
              }
              zoom={7.2}
              defaultCenter={{ lat: 42.733883, lng: 25.48583 }}
              pins={pins}
              mapControlsElem={mapControlsElem}
              filters={activeFilters}
              lang={locale}
              defaultOptions={{
                streetViewControl: false,
                mapTypeControl: false,
                scrollwheel: false,
                styles: mapStyles,
                maxZoom: 17,
                minZoom: 6,
              }}
            />
          )}
        </MapWrapperDiv>
      </VisibilitySensor>

      <div className="relative">
        <MapFilterPanelPosed
          ref={mapControlsElem}
          className=""
          pose={filtersMinimized ? `closed` : controlsPose}
          filtersMinimized={filtersMinimized}
          toggleMinimize={toggleMinimize}
          {...props}
          {...{ categories, municipalities }}
        />
      </div>
    </div>
  )
})

export default props => (
  <StaticQuery
    query={graphql`
      query NomineesQuery {
        Nominees: allNomineesYaml {
          edges {
            node {
              id
              title
              lat
              lng
              link
              municipality
              category {
                id
                color
                ...NomineeCatMultilingual
              }

              ...NomineeMultilingual
            }
          }
        }
      }
    `}
    render={({ Nominees: { edges } }) => (
      <NomineesMap {...props} items={edges.map(e => e.node)} />
    )}
  />
)

export const nomineesMapFragment = graphql`
  fragment HomeSectionNomineesMapFragment on HomeYaml {
    map {
      copyMd {
        MdNode: childMarkdownRemark {
          html
        }
      }
    }
  }
`
