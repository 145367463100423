import React from 'react'
import posed from 'react-pose'
import { graphql } from 'gatsby'
import { renderAstWithTextPose } from '../AstRenderer'
import { enhanceWithVisibilityState, enhanceWithMountState } from '../enhancers'
import VisibilitySensor from 'react-visibility-sensor'
import { compose } from 'recompose'
import ResponsivePlayer from '../ResponsivePlayer'
import HalfColumns from './HalfColumns'

const SectionPosed = posed.section({
  enter: {
    opacity: 1,
    staggerChildren: 600,
    beforeChildren: true,
  },
  exit: {
    opacity: 0,
  },
  transition: {
    duration: 1,
  },
})

function VideoSectionBase({
  video,
  contentMd,
  mounted,
  becameVisible,
  handleVisibilityChange,
  ...props
}) {
  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility={true}
      minTopValue={200}
    >
      <SectionPosed {...props}>
        <div className="container my-10">
          <HalfColumns
            left={video && <ResponsivePlayer url={video} className="mb-8" />}
            right={contentMd && renderAstWithTextPose(contentMd.remark.htmlAst)}
          />
        </div>
      </SectionPosed>
    </VisibilitySensor>
  )
}

const VideoSection = compose(
  enhanceWithMountState,
  enhanceWithVisibilityState
)(VideoSectionBase)

export const homeVideoSectionFragment = graphql`
  fragment HomeSectionVideoFragment on HomeYaml {
    videoSection {
      video
      contentMd {
        remark: childMarkdownRemark {
          htmlAst
        }
      }
    }
  }
`

export default VideoSection
