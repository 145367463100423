import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import VisibilitySensor from 'react-visibility-sensor'

import 'images/icons/locations.svg'
import 'images/icons/organizations.svg'
import 'images/icons/people.svg'
import bgSquaresPNG from 'images/bg-texture-rectangles.png'
import { ParallaxBanner } from 'react-scroll-parallax'
import { enhanceWithBreakpoints } from 'components/enhancers'

import Icon from 'components/SvgIcon'

class CountersBase extends Component {
  state = {
    isVisible: false,
    counters: {
      people: 0,
      organizations: 0,
      cities: 0,
    },
  }

  componentDidMount() {
    // Odometer = require('react-odometerjs')
    // setTimeout( this.initCounters, 1000 )
  }

  visibilityChangeHandler = isVisible => {
    if (!isVisible || this.state.isVisible) {
      return
    }

    this.setState(s => ({
      isVisible,
    }))

    this.initCounters()
  }

  initCounters = () => {
    const { counters } = this.props

    this.setState(s => ({
      counters,
    }))
  }

  render() {
    const {
      CounterNomineesComponent,
      CounterOrganizationsComponent,
      CounterCitiesComponent,
      isMinXxl,
    } = this.props

    const { counters } = this.state

    const iconSize = isMinXxl ? 100 : 80

    return (
      <VisibilitySensor onChange={this.visibilityChangeHandler}>
        <section
          id="counters"
          className="section"
          // style={{ backgroundSize: `auto 200%` }}
        >
          <ParallaxBanner
            className="bg-turquoise text-white py-0"
            style={{ height: 'auto' }}
            layers={[
              {
                image: bgSquaresPNG,
                amount: 0.3,
                expanded: true,
              },
            ]}
          >
            <div className="container overflow-hidden my-4 md:mb-4 md:mt-0">
              <div className="flex flex-row overflow-hidden md:-mx-6">
                <div className="overflow-hidden xxl:my-6 md:px-6 w-1/3 flex-no-grow">
                  <div className="text-center">
                    <div
                      className="hidden md:block"
                      style={{ color: '#4b6878' }}
                    >
                      <Icon
                        iconId="people"
                        size={iconSize}
                        className="block mx-auto"
                      />
                    </div>
                    <div className="counter counter-people md:-mt-3">
                      <CounterNomineesComponent
                        className="md:7xl"
                        value={counters.people}
                        loading={counters.people}
                      />
                    </div>

                    <div className="flex flex-col justify-center align-middle h-8 md:h-auto">
                      <FormattedMessage id="counterNominees">
                        {(...content) => (
                          <div className="leading-none md:text-2xl xxl:text-3xl">
                            {content}
                          </div>
                        )}
                      </FormattedMessage>
                    </div>
                  </div>
                </div>

                <div className="overflow-hidden xxl:my-6 md:px-6 w-1/3 flex-no-grow">
                  <div className="text-center">
                    <div
                      className="hidden md:block"
                      style={{ color: '#4b6878' }}
                    >
                      <Icon
                        iconId="organizations"
                        size={iconSize}
                        className="block mx-auto"
                      />
                    </div>
                    <div className="counter counter-organizations md:-mt-3">
                      <CounterOrganizationsComponent
                        className="md:7xl"
                        value={counters.organizations}
                        loading={counters.organizations}
                      />
                    </div>

                    <div className="flex flex-col justify-center align-middle h-8 md:h-auto">
                      <FormattedMessage id="counterOrganizations">
                        {(...content) => (
                          <div className="leading-none md:text-2xl xxl:text-3xl md:leading-normal">
                            {content}
                          </div>
                        )}
                      </FormattedMessage>
                    </div>
                  </div>
                </div>

                <div className="overflow-hidden xxl:my-6 md:px-6 w-1/3 flex-no-grow">
                  <div className="text-center">
                    <div
                      className="hidden md:block"
                      style={{ color: '#4b6878' }}
                    >
                      <Icon
                        iconId="locations"
                        size={iconSize}
                        svgClassName="block mx-auto"
                      />
                    </div>
                    <div className="counter counter-locations md:-mt-3">
                      <CounterCitiesComponent
                        className="md:7xl"
                        value={counters.cities}
                        loading={counters.cities}
                      />
                    </div>

                    <div className="flex flex-col justify-center align-middle h-8 md:h-auto">
                      <FormattedMessage id="counterCities">
                        {(...content) => (
                          <div className="leading-none md:text-2xl xxl:text-3xl">
                            {content}
                          </div>
                        )}
                      </FormattedMessage>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ParallaxBanner>
        </section>
      </VisibilitySensor>
    )
  }
}

const Counters = enhanceWithBreakpoints(CountersBase)

export default Counters

export const homeSectionCountersFragment = graphql`
  fragment HomeSectionCountersFragment on HomeYaml {
    counters {
      cities
      organizations
      people
    }
  }
`
