import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import posed from 'react-pose'
import { FormattedMessage } from 'react-intl'
import { kebabCase } from 'lodash'
import Link from 'components/L10nLink'
import screens from 'styles/tailwind/screens'
import VisibilitySensor from 'react-visibility-sensor'
import {
  enhanceWithVisibilityState,
  enhanceWithBreakpoints,
} from 'components/enhancers'
import { compose } from 'recompose'

const SponsorListPosed = posed.ul({
  enter: {
    beforeChildren: true,
    staggerChildren: 300,
  },
})

// const SponsorListItemBase =

const SponsorListItemPosed = styled(
  posed(
    React.forwardRef(
      (
        { className, logo, link: OuterLink, logoSize, onImgLoad = () => {} },
        ref
      ) => {
        const SponsorImg = logo[logoSize] ? (
          <Img
            fixed={logo[logoSize].fixed}
            // onLoad={onImgLoad}
            imgStyle={{
              maxHeight: '100%',
              width: 'auto',
            }}
          />
        ) : null

        return SponsorImg ? (
          <li
            ref={ref}
            className={`${className} block mb-4 md:mb-0 sponsor-list-item flex flex-col justify-center mx-2 md:mx-4`}
          >
            {(OuterLink && <OuterLink>{SponsorImg}</OuterLink>) || SponsorImg}
          </li>
        ) : (
          ''
        )
      }
    )
  )({
    enter: {
      scale: 1,
      opacity: 1,
      transition: {
        duration: 300,
        type: 'tween',
      },
    },

    exit: {
      scale: 1.25,
      opacity: 0,
      transition: {
        duration: 300,
        type: 'tween',
      },
    },
  })
)`
  min-width: 40px;
  height: 40px;
  transform-origin: 50% 50%;

  @media (min-width: ${screens.md}) {
    min-width: 70px;
    height: 70px;
  }
`

const SponsorsBase = ({
  items = [],
  className = '',
  becameVisible = true,
  handleVisibilityChange,
  isMinMd,
}) => (
  <VisibilitySensor onChange={handleVisibilityChange}>
    <div className={`sponsors ${className}`}>
      <h2 className="text-center text-grey-darker mb-8">
        <FormattedMessage id="poweredBy" />
      </h2>

      {items.length && (
        <SponsorListPosed
          pose={becameVisible ? `enter` : ''}
          className="sponsors-list list-reset flex flex-wrap flex-row justify-center"
        >
          {items.map(({ logo, name, link }, idx) => {
            const key = `${idx}-${kebabCase(name)}`
            const linkComponent = link
              ? ({ children }) => (
                  <Link to={link} className="hover:opacity-50 focus:opacity-50">
                    {children}
                  </Link>
                )
              : null

            return logo ? (
              <SponsorListItemPosed
                key={key}
                initialPose="exit"
                logo={logo}
                link={linkComponent}
                logoSize={isMinMd ? 'desktop' : 'mobile'}
              />
            ) : null
          })}
        </SponsorListPosed>
      )}
    </div>
  </VisibilitySensor>
)

const Sponsors = compose(
  enhanceWithVisibilityState,
  enhanceWithBreakpoints
)(SponsorsBase)

export default props => (
  <StaticQuery
    query={graphql`
      {
        file(relativeDirectory: { eq: "settings" }, name: { eq: "footer" }) {
          childSettingsYaml {
            sponsors {
              link
              logo {
                name
                desktop: childImageSharp {
                  fixed(height: 70, quality: 85) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }

                mobile: childImageSharp {
                  fixed(height: 40, quality: 85) {
                    ...GatsbyImageSharpFixed_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={({
      file: {
        childSettingsYaml: { sponsors },
      },
    }) => <Sponsors {...props} items={sponsors} />}
  />
)
