import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import posed from 'react-pose'

import Odometer from 'components/Odometer'
import NomineesMap from 'components/MapNominees'
import Sponsors from 'components/Sponsors'
import { renderAstWithTextPose } from 'components/AstRenderer'
import { renderOnce } from 'components/enhancers'

import SectionIntro from 'components/sections/Intro'
import SectionVideo from 'components/sections/Video'
import SectionArticles from 'components/sections/Articles'
import SectionCounters from 'components/sections/Counters'
import SectionNominate from 'components/sections/Nominate'

import screens from 'styles/tailwind/screens'
import ApplyPopup from '../components/PopupApply'
import NominatePopup from '../components/PopupNominate'

const ArticleFigurePosed = posed.figure({
  // hoverable: true,
  init: {
    scale: 1,
    transition: {
      duration: 500,
    },
  },
  hover: {
    scale: 1.2,
    transition: {
      duration: 500,
    },
  },
})

const SectionMap = styled.section`
  @media (min-width: ${screens.md}) {
    height: calc(100vh - 200px);
  }
`

export class IndexTemplate extends Component {
  render() {
    const {
      helmetTitle,
      helmetMeta,
      IntroTextHtml,
      nominateButtonText,
      applyButtonText,
      IntroImg,
      particlesSvgMask,
      counters,
      CounterNomineesComponent,
      CounterOrganizationsComponent,
      CounterCitiesComponent,
      videoSection,
      articles,
      articlesBtnLink,
      articlesBtnText,
      nominateLeftColumn,
      nominateRightColumn,
      nominateActions,
      mapComponent,
      applyGoogleFormCode,
      nominateGoogleFormCode,
    } = this.props

    return (
      <ApplyPopup popupContent={applyGoogleFormCode}>
        <NominatePopup popupContent={nominateGoogleFormCode}>
          {helmetTitle && <Helmet title={helmetTitle} />}
          {helmetMeta && <Helmet meta={helmetMeta} />}

          <SectionIntro
            {...{
              IntroTextHtml,
              nominateButtonText,
              applyButtonText,
              IntroImg,
              particlesSvgMask,
            }}
          />

          <SectionVideo id="mission" {...videoSection} />

          <SectionArticles
            {...{ articles, articlesBtnLink, articlesBtnText }}
          />

          <SectionMap id="map" className="section mt-8 md:mt-8">
            {mapComponent}
          </SectionMap>

          <SectionCounters
            {...{
              CounterNomineesComponent,
              CounterOrganizationsComponent,
              CounterCitiesComponent,
              counters,
            }}
          />

          <SectionNominate
            id="nominate"
            {...{
              nominateActions,
              nominateLeftColumn,
              nominateRightColumn,
            }}
          />

          <section id="sponsors">
            <div className="container">
              <Sponsors className="my-8 sm:my-20" />
            </div>
          </section>
        </NominatePopup>
      </ApplyPopup>
    )
  }
}

const IndexTemplateQueryParser = props => {
  const {
    // location,
    // pageContext: { lang },
    data: {
      generalSettings,
      homeYaml: pageData,
      particlesSvgMask,
      site: { siteMetadata },
    },
  } = props

  const nominateGoogleFormCode =
    (generalSettings.yaml && generalSettings.yaml.googleformNominateCode) || ''

  const applyGoogleFormCode =
    (generalSettings.yaml && generalSettings.yaml.googleformApplyCode) || ''

  const {
    seo = {},
    intro,
    videoSection = {},
    articles = {},
    counters = {},
    nominate = {},
    map = {},
  } = pageData

  const { siteUrl } = siteMetadata

  const helmetTitle = seo.title || null
  const helmetMeta = [
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
  ]
    .concat(
      seo.keywords && seo.keywords.length > 0
        ? {
            name: `keywords`,
            content: seo.keywords.join(`, `),
          }
        : []
    )
    .concat(
      seo.title
        ? [
            {
              property: `og:title`,
              content: seo.title,
            },
            {
              name: `twitter:title`,
              content: seo.title,
            },
          ]
        : []
    )
    .concat(
      seo.description
        ? [
            {
              property: `og:description`,
              content: seo.description,
            },
            {
              name: `twitter:description`,
              content: seo.description,
            },
          ]
        : []
    )
    .concat(
      seo.ogimage && seo.ogimage.sharp
        ? [
            {
              property: `og:image`,
              content: `${siteUrl}${seo.ogimage.sharp.original.src}`,
            },
            {
              name: `twitter:image`,
              content: `${siteUrl}${seo.ogimage.sharp.original.src}`,
            },
          ]
        : []
    )

  const {
    items: articleItems = [],
    buttonText: articlesBtnText,
    buttonLink: articlesBtnLink,
  } = articles

  const nominateColumns = (nominate && nominate.columnsMd) || []
  const { actions: nominateActions } = nominate

  const {
    introTextMd,
    image: introImage,
    nominateButtonText,
    applyButtonText,
  } = intro

  const IntroPosedContainer = posed.div({
    // enter: {  },
    enter: {
      applyAtStart: { display: 'block' },
      opacity: 1,
      staggerChildren: 150,
      beforeChildren: true,
    },
    exit: {
      applyAtEnd: { display: 'none' },
      opacity: 0,
    },
  })

  const IntroTextHtml = renderOnce(
    React.forwardRef((props, ref) => {
      return (
        <IntroPosedContainer initialPose="exit" {...props} ref={ref}>
          {introTextMd && introTextMd.childMarkdownRemark
            ? renderAstWithTextPose(introTextMd.childMarkdownRemark.htmlAst)
            : null}
        </IntroPosedContainer>
      )
    })
  )

  const IntroImg = props =>
    introImage && introImage.childImageSharp ? (
      <Img {...{ ...props, fluid: introImage.childImageSharp.fluid }} />
    ) : null

  const articlesData = articleItems.map(
    ({ title, image = {}, excerptMd }, idx) => {
      const MdNode = excerptMd ? excerptMd.childMarkdownRemark : {}
      const html = MdNode.htmlAst ? renderAstWithTextPose(MdNode.htmlAst) : ''

      let ImageComponent

      if (image) {
        if (image.childImageSharp) {
          ImageComponent = ({ className }) => (
            <div className="overflow-hidden">
              <ArticleFigurePosed>
                <Img {...{ className }} fluid={image.childImageSharp.fluid} />
              </ArticleFigurePosed>
            </div>
          )
        } else {
          ImageComponent = ({ className }) => (
            <img {...{ className }} src={image.publicURL} alt="" />
          )
        }
      }

      return {
        html,
        title,
        ImageComponent,
      }
    }
  )

  const CounterNomineesComponent = ({ value, ...props }) => (
    <Odometer
      {...props}
      format="d"
      duration="1500"
      value={value}
      loading={value}
    />
  )

  const CounterOrganizationsComponent = ({ value, ...props }) => (
    <Odometer
      {...props}
      format="d"
      duration="2000"
      value={value}
      loading={value}
    />
  )

  const CounterCitiesComponent = ({ value, ...props }) => (
    <Odometer
      {...props}
      format="d"
      duration="2500"
      value={value}
      loading={value}
    />
  )

  const nominateLeftColumn =
    nominateColumns[0] && nominateColumns[0].childMarkdownRemark
      ? renderAstWithTextPose(nominateColumns[0].childMarkdownRemark.htmlAst)
      : ''

  const nominateRightColumn =
    nominateColumns[1] && nominateColumns[1].childMarkdownRemark
      ? renderAstWithTextPose(nominateColumns[1].childMarkdownRemark.htmlAst)
      : ''

  const mapCopy = map.copyMd.MdNode ? map.copyMd.MdNode.html : ''

  const mapComponent = <NomineesMap copy={mapCopy} />

  const templateProps = {
    helmetTitle,
    helmetMeta,
    nominateButtonText,
    applyButtonText,
    IntroTextHtml,
    particlesSvgMask,
    IntroImg,
    videoSection,
    articles: articlesData,
    articlesBtnText,
    articlesBtnLink,
    mapComponent,
    mapCopy,
    counters,
    CounterNomineesComponent,
    CounterOrganizationsComponent,
    CounterCitiesComponent,
    nominateLeftColumn,
    nominateRightColumn,
    nominateActions,
    nominateGoogleFormCode,
    applyGoogleFormCode,
  }

  return <IndexTemplate {...templateProps} />
}

export default IndexTemplateQueryParser

export const pageSeoFragment = graphql`
  fragment PageSeoFragment on CMSPage {
    seo {
      title
      description
      keywords
      ogimage {
        sharp: childImageSharp {
          original {
            src
          }
        }
      }
    }
  }
`

export const homepageQuery = graphql`
  query homeQuery($lang: String!) {
    ...IntroParticlesSvgMask
    ...SettingsNominateGoogleFormCodeFragment
    ...SettingsApplyGoogleFormCodeFragment

    site {
      siteMetadata {
        siteUrl
      }
    }

    homeYaml(fields: { lang: { eq: $lang } }) {
      fields {
        lang
      }

      ...HomeSectionIntroFragment
      ...HomeSectionVideoFragment
      ...HomeSectionArticlesFragment
      ...HomeSectionNomineesMapFragment
      ...HomeSectionCountersFragment
      ...HomeSectionNominateFragment

      ...PageSeoFragment
    }
  }
`
