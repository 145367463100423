import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import { Parallax } from 'react-scroll-parallax'
import posed, { PoseGroup } from 'react-pose'
import { Location } from '@reach/router'
import { lifecycle, withState, compose, withHandlers } from 'recompose'
import Particles, { particlesParams } from 'components/Particles'
import styled from 'styled-components'
import screens from 'styles/tailwind/screens'
import VisibilitySensor from 'react-visibility-sensor'
import {
  enhanceWithBreakpoints,
  enhanceWithVisibilityState,
  enhanceWithMountState,
} from 'components/enhancers'
import Icon from 'components/SvgIcon'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { injectIntl } from 'react-intl'

import ArrowPlanetSVG from 'images/icons/planet-arrow.svg'
import ArrowPlanetTextBG from 'images/text-view-the-map-bg@2x.png'
import ArrowPlanetTextEN from 'images/text-view-the-map-en@2x.png'
import HandPointerSVG from 'images/icons/handpointer.svg'
import Button from '../Button'
import { NominatePopupContext } from '../PopupNominate'
import { ApplyPopupContext } from '../PopupApply'

const arrowPlanetText = {
  en: ArrowPlanetTextEN,
  bg: ArrowPlanetTextBG,
}

const SectionPosed = styled(
  posed.section({
    enter: {
      opacity: 1,
      scaleY: 1,
      transition: {
        type: 'spring',
        default: { duration: 400 },
      },
      delayChildren: 50,
      staggerChildren: 300,
      staggerDirection: -1,
      beforeChildren: true,
    },

    exit: {
      scaleY: 0.1,
      opacity: 0,
      duration: 400,
    },
  })
)`
  min-height: calc(100vh - 50px);
  opacity: 0;

  @media (min-width: ${screens.md}) and (min-height: 720px) {
    min-height: calc(100vh - 200px);
  }
`

const IntroRightPosed = styled(
  posed.div({
    enter: {
      beforeChildren: true,
      // staggerChildren: 10000,
      delayChildren: 600,
      // applyAtStart: { visibility: 'visible' },
      opacity: 1,
    },

    exit: {
      // applyAtEnd: { visiblity: 'hidden' },
      opacity: 0,
    },

    transition: {
      duration: 400,
    },
  })
)`
  position: relative;
`

const IntroTextWrapper = styled.div`
  @media (max-width: 320px) {
    &[class] {
      margin-left: 1rem !important;
      margin-right: 1rem !important;
    }
  }
`

const PlanetArrowTextPosed = styled(
  posed.figcaption({
    enter: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 400,
      },
    },

    exit: {
      opacity: 0,
      x: '-30%',
    },
  })
)`
  right: 100%;
  width: 158px;
  height: 30px;
`

const PlanetArrowBase = injectIntl(
  ({ intl: { locale }, forwardedRef, ...props }) => (
    <figure ref={forwardedRef} {...props}>
      <Icon iconId={ArrowPlanetSVG.id} size="100%" />
      <PlanetArrowTextPosed className="absolute pin-b">
        {arrowPlanetText[locale] && (
          <img
            className="block"
            src={arrowPlanetText[locale]}
            width="158"
            height="30"
          />
        )}
      </PlanetArrowTextPosed>
    </figure>
  )
)

const PlanetArrowPosed = styled(
  posed(
    React.forwardRef((props, ref) => (
      <PlanetArrowBase {...props} forwardedRef={ref} />
    ))
  )({
    enter: {
      opacity: 1,
      rotate: 0,
      beforeChildren: true,
      transition: {
        duration: 400,
      },
      // delay: ({ delay }) => delay,
    },

    exit: {
      opacity: 0,
      rotate: 45,
    },
  })
)`
  transform-origin: 0 -100%;

  &[class] {
    @media (min-width: ${screens.xxl}) and (max-height: 780px) {
      margin-bottom: 4rem !important;
    }
  }
`

const IntroRightColumnWrapper = styled.div`
  margin-top: -25%;

  @media (min-width: ${screens.lg}) {
    margin-top: 0;
  }
`

const IntroRightColumn = enhanceWithBreakpoints(
  ({
    partParams, // mounted,
    isVisible,
    IntroImg,
    isMinLg,
  }) => (
    <IntroRightPosed className="lg:m-0 p-8 lg:px-12 lg:py-20">
      <IntroImg
        className="mx-auto lg:my-8"
        style={{
          maxWidth: 412,
        }}
      />

      <PoseGroup>
        {isVisible && (
          <Particles
            key="particles"
            params={partParams}
            className="absolute pin"
            width="100%"
            height="100%"
          />
        )}
      </PoseGroup>

      {/* {isMinLg && (
        <PlanetArrowPosed
          style={{ width: '100px', height: '60px', color: '#fff' }}
          className="absolute pin-l pin-b xl:mb-10 xxl:mb-0"
        />
      )} */}
      {/* ] */}
      {/* )} */}
    </IntroRightPosed>
  )
)

const IntroButtonHandPointerPosed = styled(
  posed(
    React.forwardRef((props, ref) => (
      <div ref={ref} {...props}>
        <Icon iconId={HandPointerSVG.id} size="64" />
      </div>
    ))
  )({
    enter: {
      applyAtStart: { display: 'block' },
      opacity: 1,
      scale: 1,
      y: '50%',
      transition: {
        // type: 'decay',
        duration: 800,
        ease: 'easeInOut',
      },
    },

    exit: {
      applyAtEnd: { display: 'none' },
      opacity: 0,
      scale: 1.35,
      y: '200%',
      transition: {
        // type: 'decay',
        duration: 600,
        ease: 'easeInOut',
      },
    },
  })
)`
  display: none;
`

const FlexContainerGhostAfter = styled.div`
  &::before {
    content: '';
    display: block;
  }

  @media (min-width: ${screens.lg}) {
    &::before {
      display: none;
    }
  }
`

const IntroSectionBase = ({
  nominateButtonText,
  applyButtonText,
  IntroTextHtml,
  introTextHtmlClassname,
  IntroImg,
  particlesSvgMask,
  mounted,
  isVisible,
  handleVisibilityChange,
  handleHandPointerPoseComplete,
  handPointerPose,
  isMaxMd,
  isMinSm,
  isMinMd,
  isMinLg,
  isMinXl,
}) => {
  const partParams = {
    ...particlesParams,
  }

  partParams.polygon.url = particlesSvgMask.publicURL

  const isOneColumn = isMaxMd

  // if ( isMin )
  if (isOneColumn) {
    partParams.polygon.scale = 0.42
  }

  if (isMinSm) {
    // partParams.polygon.scale = .4
    partParams.polygon.scale = 0.6
  }

  if (isMinMd) {
    // partParams.polygon.scale = .6
  }

  if (isMinLg) {
    partParams.polygon.scale = 0.62
  }

  if (isMinXl) {
    partParams.polygon.scale = 0.65
  }

  const sectionPose = mounted ? 'enter' : 'exit'

  const { togglePopup: nominatePopupToggle } = useContext(NominatePopupContext)
  const { togglePopup: applyPopupToggle } = useContext(ApplyPopupContext)

  return (
    <Location>
      {({ location }) => {
        return (
          <VisibilitySensor
            onChange={handleVisibilityChange}
            partialVisibility={true}
            minTopValue={1}
          >
            <SectionPosed
              key="section-intro"
              pose={sectionPose}
              id="intro"
              className="section flex flex-row items-stretch lg:py-8 bg-gradient-primary-secondary-bottom text-white weight-normal"
            >
              <div className="container flex items-stretch lg:px-10">
                <FlexContainerGhostAfter className="flex flex-1 flex-col-reverse items-stretch overflow-hidden justify-between lg:flex-row lg:flex-wrap lg:-mx-10">
                  <IntroTextWrapper className="intro-text flex align-middle font-roboto mx-1 sm:mx-8 pb-4 sm:pb-0 lg:pt-16 lg:py-12 lg:mx-0 lg:px-10 lg:w-1/2">
                    <div className="relative lg:self-center">
                      <div className={introTextHtmlClassname}>
                        <IntroTextHtml />

                        <div className="btn-group inline-flex">
                          <Button
                            variant="violet"
                            size="lg"
                            onClick={nominatePopupToggle}
                          >
                            {nominateButtonText}
                          </Button>

                          <Button
                            variant="violet"
                            size="lg"
                            onClick={applyPopupToggle}
                          >
                            {applyButtonText}
                          </Button>
                        </div>
                      </div>

                      <IntroButtonHandPointerPosed
                        withParent={false}
                        initialPose="exit"
                        pose={handPointerPose}
                        onPoseComplete={handleHandPointerPoseComplete}
                        className="absolute pin-b "
                      />
                    </div>
                  </IntroTextWrapper>

                  <IntroRightColumnWrapper className="relative items-stretch lg:flex lg:p-0 lg:w-1/2">
                    <Parallax
                      disabled={isOneColumn}
                      className="flex-grow self-center"
                      offsetYMax={10}
                      offsetYMin={-30}
                      slowerScrollRate={false}
                    >
                      <AnchorLink to="/#map">
                        <IntroRightColumn
                          {...{ partParams, mounted, isVisible, IntroImg }}
                        />
                      </AnchorLink>
                    </Parallax>
                  </IntroRightColumnWrapper>
                </FlexContainerGhostAfter>
              </div>
            </SectionPosed>
          </VisibilitySensor>
        )
      }}
    </Location>
  )
}

const enhanceWithPathsegOnMount = lifecycle({
  componentDidMount() {
    require('pathseg')
  },
})

const enhanceWithIntroTextHtmlClassnameState = withState(
  'introTextHtmlClassname',
  'setIntroTextHtmlClassname',
  ''
)

const enhanceWithHandPointerPoseState = withState(
  'handPointerPose',
  'setHandPointerPose',
  'exit'
)
const enhanceWithHandPointerTimeoutState = withState(
  'changePoseTimeout',
  'updateChangePoseTimeout',
  null
)
const enhanceWithHandPointerOnPoseComleteHandler = withHandlers({
  handleHandPointerPoseComplete: ({ setIntroTextHtmlClassname }) => pose => {
    if (pose === 'enter' && setIntroTextHtmlClassname) {
      setIntroTextHtmlClassname('hover')
    }
  },
})
const enhanceWithHandPointerLifecycle = lifecycle({
  componentDidMount() {
    if (!this.props.isMinMd) {
      return
    }

    // enter timeout
    this.props.updateChangePoseTimeout(
      setTimeout(() => {
        this.props.setHandPointerPose('enter')

        // exit timeout
        this.props.updateChangePoseTimeout(
          setTimeout(() => {
            if (this.props.setIntroTextHtmlClassname) {
              this.props.setIntroTextHtmlClassname('')
            }

            this.props.setHandPointerPose('exit')
          }, 2000)
        )
      }, 5000)
    )
  },

  componentWillUnmount() {
    clearTimeout(this.props.changePoseTimeout)
  },
})

const IntroSection = compose(
  enhanceWithPathsegOnMount,
  enhanceWithMountState,
  enhanceWithBreakpoints,
  enhanceWithVisibilityState,

  enhanceWithIntroTextHtmlClassnameState,
  enhanceWithHandPointerPoseState,
  enhanceWithHandPointerTimeoutState,
  enhanceWithHandPointerLifecycle,
  enhanceWithHandPointerOnPoseComleteHandler
)(IntroSectionBase)

export default IntroSection

export const particlesSVGMask = graphql`
  fragment IntroParticlesSvgMask on Query {
    particlesSvgMask: file(relativePath: { eq: "planet-outline2-gravit.svg" }) {
      publicURL
    }
  }
`

export const homeSectionIntroFragment = graphql`
  fragment HomeSectionIntroFragment on HomeYaml {
    intro {
      nominateButtonText
      applyButtonText
      introTextMd {
        childMarkdownRemark {
          htmlAst
        }
      }

      image {
        childImageSharp {
          fluid(maxWidth: 505, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
